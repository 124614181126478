import React, { useState } from "react";

import Card from "./components/Card";
import { Data } from "./data";

export function App() {
  const [text, setText] = useState("");
  const [filteredData, setFilteredData] = useState(Data);

  const handleClick = (e) => {
    if (text.length) {
      setFilteredData(
        Data.filter(
          (item) =>
            item.title.toLowerCase().includes(text.toLowerCase()) ||
            item.tags.some((tag) =>
              tag.toLowerCase().includes(text.toLowerCase())
            )
        )
      );
    } else {
      setFilteredData(Data);
    }
  };

  return (
    <>
      <table cellPadding="2" cellSpacing="2" className="gif_table">
        <tbody>
          <tr>
            <td>
              <img src="/ie_logo.gif" />
            </td>
            <td>
              <img src="/ns_logo.gif" />
            </td>
            <td>
              <img src="/noframes.gif" />
            </td>
            <td>
              <img src="/hot.gif" />
            </td>
          </tr>
        </tbody>
      </table>
      <h1 className="text-center" id="hero">
        <blink>
          <font color="#FF0000">I</font>
          <font color="#FFB400">s</font>
          <font color="#4Bff00">u</font>
          <font color="#00B4ff">g</font>
          <font color="#4B00ff">i</font>
          <font color="#FF00ff">f</font>
        </blink>
      </h1>
      <div className="search_bar">
        <input
          type="text"
          className="search-query search_input"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <a className="btn btn-primary btn-large" onClick={handleClick}>
          Search
        </a>
      </div>
      <div className="results">
        {filteredData.map((gif) => (
          <Card {...gif} key={gif.id} />
        ))}
      </div>
    </>
  );
}
