export const Data = [
  {
    id: 1,
    title: "No no no",
    src: "/gifs/nonono.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Non", "Nope", "No", "Negative"],
  },
  {
    id: 2,
    title: "Oui oui oui",
    src: "/gifs/ouioui.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Oui", "Yup", "Yes", "Positive"],
  },
  {
    id: 3,
    title: "C'est la fête",
    src: "/gifs/clafete.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "fiesta", "Pisser", "Fete", "Happy", "Brest"],
  },
  {
    id: 4,
    title: "Chiant",
    src: "/gifs/chiant.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "boring", "sleep", "sad", "bored"],
  },
  {
    id: 5,
    title: "Eclatéééé",
    src: "/gifs/éclatéééé.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Eclaté", "Eclatax", "Eclate"],
  },
  {
    id: 6,
    title: "Miam miam",
    src: "/gifs/miammiam.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Nomnomnom", "Miam", "Popcorn"],
  },
  {
    id: 7,
    title: "Le bonheur",
    src: "/gifs/bonheur.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Happy", "Heureux", "Smile"],
  },
  {
    id: 8,
    title: "Pas mal",
    src: "/gifs/pasmal.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Not bad", "Ok", "Okay", "Pass"],
  },
  {
    id: 9,
    title: "Psychopathe",
    src: "/gifs/psychopathe.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Crazy", "Langue", "Metro", "Nantes", "Bizarre", "Chelou"],
  },
  {
    id: 10,
    title: "Reveillé",
    src: "/gifs/reveille.gif",
    tags: ["Isuru", "Izuzu", "Izugif", "Matin", "Morning", "Sleep", "Dormir", "Se réveiller", "Rêve", "Dodo", "Fatigué"],
  },
];
