export default function Card({ src, title, tags }) {
  return (
    <div className="card well">
      <div>
        <img src={src} className="card_image" />
      </div>
      <div className="card_content">
        <strong className="card_title">{title}</strong>
        <div className="card_tags">
          {tags.map((tag) => (
            <span className="badge" key={tag}>{tag}</span>
          ))}
        </div>
      </div>
    </div>
  );
}
